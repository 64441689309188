<template>
  <div>
    <h1 class="title m-4">贈獎管理－紅利獎設定</h1>
    <div class="container d-flex justify-content-between align-items-center">
      <form class="search-bar d-flex align-items-center">
        <div class="keywords">
          <h5>關鍵字搜尋:</h5>
          <div class="d-flex">
            <input
              type="text"
              class="form-control mr-1"
              name="keyword"
              id="keyword"
              placeholder="輸入活動名稱"
              v-model.trim="keyword"
            />
            <button class="btn-main btn searching" type="button" @click="search">
              <i class="fas fa-search mr-1"></i>查詢
            </button>
          </div>
        </div>
      </form>
      <div class="add text-right d-flex">
        <button class="btn-main btn" @click="toEdit(0, 'new')">＋新增紅利獎</button>
      </div>
    </div>
    <div class="container">
      <div class="page">
        <div class="select-perpage d-flex justify-content-end align-items-center">
          <span>每頁顯示</span>
          <select class="form-select" v-model.number="perpage">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
          </select>
          <span>筆</span>
        </div>
      </div>
      <table class="table table-hover mt-4">
        <thead class="bg-thead">
          <tr>
            <th width="240" class="text-center" @click="changeType('activityName')">
              活動名稱
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'activityName'">
                <i class=" fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="240" class="text-center" @click="changeType('created_at')">
              建立時間
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'created_at'">
                <i class=" fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="240" class="text-center" @click="changeType('startDate')">
              獎項兌換時間
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'startDate'">
                <i class=" fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="240" class="text-center" @click="changeType('endDate')">
              獎項兌換期限
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'endDate'">
                <i class=" fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="240" class="text-center" @click="changeType('total')">
              獎品總數量
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'total'">
                <i class=" fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="240" class="text-center" @click="changeType('remain')">
              剩餘數量
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'remain'">
                <i class=" fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th class="text-center">兌換名單</th>
            <th width="180" class="text-center">操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in displayRecords()" :key="item.groupID">
            <td class="text-center">{{ item.activityName }}</td>
            <td class="text-center">{{ item.created_at }}</td>
            <td class="text-center">{{ item.startDate }}</td>
            <td class="text-center">{{ item.endDate }}</td>
            <td class="text-center">{{ toThousands(item.total) }}</td>
            <td class="text-center">{{ toThousands(item.remain) }}</td>
            <td class="text-center">
              <button
                class="btn-table btn-main btn btn-sm"
                v-if="new Date() >= new Date(item.startDate)"
                @click="toWinnerList(item.activityID, item.groupID)"
              >
                <i class="far fa-file-alt"></i>
              </button>
            </td>
            <td>
              <!-- v-if="new Date() < new Date(item.startDate)" -->
              <button
                class="btn-table btn-success btn btn-sm"
                @click="toEdit(item.activityID, item.groupID)"
              >
                <i class="fas fa-pen"></i>
              </button>
              <!-- <button
                class="btn-table btn-danger btn btn-sm"
                @click="delModal(item)"
                v-if="new Date() > new Date(item.startDate)"
              >
                <i class="fas fa-trash-alt"> </i>
              </button> -->
            </td>
          </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-end">
        <pagination
          v-model="page"
          @update:modelValue="updateHandler"
          :pageNum="pageNum"
          :totalPageCount="totalPageCount"
          :records="pageSize"
          :per-page="perpage"
          @paginate="callback"
        />
      </div>
    </div>
  </div>
  <!-- Modal -->
  <div
    class="modal fade"
    id="delBonusModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content border-0">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            <span>刪除抽獎設定</span>
          </h5>
        </div>
        <div class="modal-body">
          是否刪除
          <strong class="text-danger"> {{ bonusDetails.activityName }}</strong>
          (刪除後將無法恢復)。
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary" @click="delCancelModal()">
            取消
          </button>
          <button class="btn btn-danger btn-sm py-2" @click="delConfirm(bonusDetails)">
            確認刪除
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import Swal from 'sweetalert2';
import Pagination from '../components/pagination.vue';

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      keyword: '',
      staffId: '',
      tableData: '',
      bonusDetails: '',
      pageSize: '',
      perpage: 30,
      page: 1,
      sortType: 'startDate',
      isReverse: false,
    };
  },
  methods: {
    getFormData(object) {
      // 轉換成 FormData 格式
      const formData = new FormData();
      const keys = Object.keys(object);
      for (let i = 0; i < keys.length; i += 1) {
        formData.append(keys[i], object[keys[i]]);
      }
      return formData;
    },
    convert(str) {
      // 日期格式轉換
      const date = new Date(str);
      const mnth = `0${date.getMonth() + 1}`.slice(-2);
      const day = `0${date.getDate()}`.slice(-2);
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(date.getFullYear())) {
        return '';
        // eslint-disable-next-line no-else-return
      } else {
        return [date.getFullYear(), mnth, day].join('/');
      }
    },
    convertTime(str) {
      // 日期 & 時間格式轉換
      const date = new Date(str.replace(/\s/, 'T'));
      const mnth = `0${date.getMonth() + 1}`.slice(-2);
      const day = `0${date.getDate()}`.slice(-2);
      const hours = `0${date.getHours()}`.slice(-2);
      const minutes = `0${date.getMinutes()}`.slice(-2);
      // const seconds = (`0${date.getSeconds()}`).slice(-2);
      return `${[date.getFullYear(), mnth, day].join('/')} ${[hours, minutes].join(':')} `;
    },
    toThousands(num) {
      // 千分位數加逗號
      let result = '';
      let counter = 0;
      // eslint-disable-next-line no-param-reassign
      num = (num || 0).toString();
      for (let i = num.length - 1; i >= 0; i -= 1) {
        counter += 1;
        result = num.charAt(i) + result;
        if (!(counter % 3) && i !== 0) {
          result = `,${result}`;
        }
      }
      return result;
    },
    search() {
      // 列表、搜尋
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/award/awardBonusList`;
      const formData = new FormData();
      formData.append('keyword', vm.keyword);
      vm.$http
        .post(api, formData)
        .then((res) => {
          if (res.data.status === 200) {
            vm.tableData = res.data.data;
            for (let i = 0; i < vm.tableData.length; i += 1) {
              vm.tableData[i].startDate = vm.convertTime(vm.tableData[i].startDate); // 開獎日時間格式轉換
              vm.tableData[i].endDate = vm.convertTime(vm.tableData[i].endDate); // 領獎期限時間格式轉換
            }
          } else {
            vm.tableData = [];
            Swal.fire({
              icon: 'warning',
              text: '查無相關資訊',
              confirmButtonColor: '#ec7805',
              confirmButtonText: '關閉',
              allowOutsideClick: false,
            });
          }
          vm.pageSize = vm.tableData.length;
          // eslint-disable-next-line no-unused-vars
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          // console.log(error);
        });
    },
    toWinnerList(activityID, bonusGroupID) {
      // 前往中獎名單
      const vm = this;
      vm.$router.push(
        // eslint-disable-next-line
        `/admin/bonus_winnerlist/${vm.staffId}/${activityID}/${bonusGroupID}`
      );
    },
    toEdit(activityID, bonusGroupID) {
      // 前往新增表單
      const vm = this;
      vm.$router.push(`/admin/bonus_add/${vm.staffId}/${activityID}/${bonusGroupID}`);
    },
    delModal(details) {
      // open delete modal
      const vm = this;
      vm.bonusDetails = details;
      $('#delBonusModal').modal('show');
    },
    delCancelModal() {
      // 取消 delete button
      $('#delBonusModal').modal('hide');
    },
    delConfirm(details) {
      // 確認刪除button
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/award/awardLotteryDelete`;
      const formData = new FormData();
      formData.append('lotteryGroupID', details.lotteryGroupID);
      vm.$http
        .post(api, formData)
        .then((res) => {
          if (res.data.status === 200) {
            $('#delBonusModal').modal('hide');
            vm.search();
          } else {
            $('#delBonusModal').modal('hide');
          }
          // eslint-disable-next-line no-unused-vars
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          // console.log(error);
        });
    },
    // eslint-disable-next-line no-unused-vars
    callback(page) {
      // 分頁
    },
    displayRecords() {
      // 分頁
      const vm = this;
      const startIndex = vm.perpage * (vm.page - 1);
      const endIndex = vm.perpage + startIndex;
      return vm.sortData.slice(startIndex, endIndex);
    },
    changeType(type) {
      // 排序功能type轉換
      const vm = this;
      if (vm.sortType === type) {
        vm.isReverse = !vm.isReverse;
      } else {
        vm.isReverse = true;
      }
      vm.sortType = type;
    },
  },
  computed: {
    sortData() {
      // 排序 Data
      const vm = this;
      const sortList = Object.assign([], vm.tableData);
      return sortList.sort((a, b) => {
        const type = vm.sortType;
        const A = a[type];
        const B = b[type];
        if (vm.isReverse) {
          if (A === null || B === null) {
            if (A === null && B !== null) {
              return -1;
              // eslint-disable-next-line no-else-return
            } else if (A !== null && B === null) {
              return 1;
            } else {
              return 0;
            }
          } else {
            // eslint-disable-next-line no-lonely-if
            if (typeof A === 'number') {
              if (B > A) {
                return -1;
              }
              if (A > B) {
                return 1;
              }
              return 0;
              // eslint-disable-next-line no-else-return
            } else {
              return A.localeCompare(B, 'zh-Hant');
            }
          }
          // eslint-disable-next-line no-else-return
        } else {
          // eslint-disable-next-line no-lonely-if
          if (A === null || B === null) {
            if (B === null && A !== null) {
              return -1;
              // eslint-disable-next-line no-else-return
            } else if (B !== null && A === null) {
              return 1;
            } else {
              return 0;
            }
          } else {
            // eslint-disable-next-line no-lonely-if
            if (typeof A === 'number') {
              if (A > B) {
                return -1;
              }
              if (B > A) {
                return 1;
              }
              return 0;
              // eslint-disable-next-line no-else-return
            } else {
              return B.localeCompare(A, 'zh-Hant');
            }
          }
        }
      });
    },
  },
  created() {
    this.staffId = this.$route.params.staffId;
    this.search();
  },
};
</script>
